<template>
  <div class="detail-intro-comments part" :class="{skeleton: !state.loaded }">
    <div class="comment">
      <div class="form">
        <div class="title font-md bold">응원 한마디</div>
        <div class="input">
          <textarea class="form-control border-focus-point" :placeholder="$lib.isPreviewPage() ? '미리보기에서는 지원하지 않습니다.' : $store.state.account.loggedIn ? '응원 한마디를 남겨주세요.' : '클릭하여 로그인하세요.'"
                    :readonly="!$store.state.account.loggedIn" :disabled="$lib.isPreviewPage()" @focus="checkLogin()" ref="commentTextareaRef"></textarea>
          <button class="btn btn-bordered-point font-sm" @click="post()" v-if="$store.state.account.loggedIn && !$lib.isPreviewPage()">등록</button>
        </div>
      </div>
      <div class="list" v-if="Array.isArray(state.comments) && state.comments.length">
        <ul class="tight">
          <li class="font-sm" v-for="(c, idx) in state.comments" :key="idx">
            <MemberPicture :memberSeq="c.memberSeq"/>
            <div class="root">
              <b>{{ c.memberName }}</b>
              <span class="font-xs">{{ c.createDate }}</span>
              <div class="actions" v-if="c.memberSeq && c.memberSeq === $store.state.account.memberSeq">
                <button class="btn btn-default font-xs" @click="edit(c)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button class="btn btn-default font-xs" @click="remove(c.commentSeq)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="content">
              <span v-html="c.content"></span>
            </div>
            <ul class="tight" v-if="c.children.length">
              <li v-for="(c, idx2) in c.children" :key="idx2">
                <MemberPicture :memberSeq="c.memberSeq"/>
                <div class="root">
                  <b>{{ c.memberName }}</b>
                  <span class="font-xs">{{ c.createDate }}</span>
                </div>
                <div class="content">
                  <span v-html="c.content"></span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="load" v-if="state.paginationInfo"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import MemberPicture from "@/components/MemberPicture";

function Component(initialize) {
  this.name = "pageProjectDetailComments";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture, Pagination},
  props: {
    projectSeq: Number,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.comments.push({
          content: "Please wait a moment. Please wait a moment.",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          children: []
        });
      }

      load();
    });

    const state = reactive({
      pageIndex: 1,
      loaded: false,
      comments: [],
      paginationInfo: {},
    });

    const commentRef = ref();
    const commentTextareaRef = ref();

    const checkLogin = () => {
      !store.state.account.loggedIn && store.dispatch("goLoginPage");
    };

    const load = (pageIndex) => {
      state.pageIndex = pageIndex ? pageIndex : 1;
      state.loaded = false;

      const args = {
        pageIndex: state.pageIndex,
        pageUnit: 5,
        pageSize: 5,
      };

      http.get(`/api/reward/projects/${props.projectSeq}/intro-comments`, args).then(({data}) => {
        state.loaded = true;
        state.comments = data.body.commentList;
        state.paginationInfo = data.body.paginationInfo;
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const post = () => {
      const content = commentTextareaRef.value.value;

      if (!content?.trim()) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        commentTextareaRef.value.focus();
        return;
      }

      const args = {
        content: content,
        createId: store.state.account.memberSeq
      };

      http.post(`/api/reward/projects/${props.projectSeq}/intro-comments`, args).then(() => {
        load();
        commentTextareaRef.value.value = "";
      });
    };

    const patch = ({commentSeq, content, updateId}) => {
      http.patch(`/api/reward/projects/${props.projectSeq}/intro-comments/${commentSeq}`, {content, updateId}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        load(state.pageIndex);
      }).catch(httpError());
    };

    const edit = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content,
          maxByte: 500,
        },
        callback: `${component.name}.patch`,
      });
    };

    const remove = (commentSeq) => {
      if (confirm("삭제하시겠습니까?")) {
        const args = {
          updateId: store.state.account.memberSeq
        };

        http.delete(`/api/reward/projects/${props.projectSeq}/intro-comments/${commentSeq}`, args).then(() => {
          store.commit("setSwingMessage", "삭제하였습니다.");
          load(state.pageIndex);
        });
      }
    };

    return {component, state, commentRef, commentTextareaRef, checkLogin, post, patch, edit, remove, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-intro-comments.skeleton {
  .comment .list li {
    .img, .root b, .root > span, .content > span {
      @include skeleton;
    }
  }
}
</style>