<template>
  <div class="comment font-sm" :data-depth="depth" :class="{skeleton}">
    <div class="wrapper">
      <MemberPicture :memberSeq="createId || memberSeq"/>
      <div class="root">
        <div class="profile">
          <b v-if="memberAuth && memberAuth.includes('ADMIN')">오마이컴퍼니</b>
          <b v-else>{{ hideInvestorNameYn === "Y" ? "키다리 후원자" : memberName }}</b>
          <span class="font-xs">{{ createDate }}</span>
          <span class="font-xs ml-1 color-secondary" v-if="page === 'cheer' && Number(depth) === 0">({{ $lib.getNumberFormat(totalAmt) }}원 후원)</span>
        </div>
        <div class="actions">
          <template v-if="Number(depth) === 0 && ($store.state.account.memberAuth.includes('ADMIN') || (openId && openId === $store.state.account.memberSeq))">
            <button class="btn btn-default font-xs"
                    @click="reply({groupNum, createId, investorSeq})" title="답변하기">
              <i class="fa fa-reply"></i>
            </button>
            <button class="btn btn-default font-xs" @click="hide(groupNum, restoreFlag, commentBlindSeq)" title="비공개 처리하기" v-if="page === 'cheer' && (!commentBlindSeq || restoreFlag === 'Y')">
              <i class="fa fa-ban"></i>
            </button>
            <button class="btn btn-default font-xs" @click="restore(commentBlindSeq, restoreFlag)" title="복구하기" v-else-if="page === 'cheer' && (commentBlindSeq  || restoreFlag !== 'Y')">
              <i class="fa fa-repeat"></i>
            </button>
          </template>
          <template v-if="(createId && createId === $store.state.account.memberSeq) || (memberSeq && memberSeq === $store.state.account.memberSeq)">
            <button class="btn btn-default font-xs" @click="edit({commentSeq, createId, content})">
              <i class="fa fa-pencil"></i>
            </button>
            <button class="btn btn-default font-xs" @click="remove(commentSeq)">
              <i class="fa fa-trash"></i>
            </button>
          </template>
        </div>
      </div>
      <div class="content">
        <span v-html="content"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import store from "@/scripts/store";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "componentComment";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture},
  mixins: [mixin],
  props: {
    change: Function,
    commentSeq: Number,
    content: String,
    commentBlindSeq: Number,
    createDate: String,
    createId: String,
    delFlag: String,
    depth: Number,
    groupNum: Number,
    hideInvestorNameYn: String,
    investSeq: Number,
    investorSeq: Number,
    memberSeq: String,
    memberName: String,
    memberAuth: String,
    memberType: String,
    openId: String,
    orderNum: Number,
    page: String,
    pageIndex: Number,
    projectSeq: Number,
    projectType: String,
    restoreFlag: String,
    skeleton: Boolean,
    totalAmt: Number,
    updateDate: String,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({});

    const reply = ({groupNum, createId, investorSeq}) => {
      const params = {
        groupNum,
      };

      createId && (params.createId = createId);
      investorSeq && (params.investorSeq = investorSeq);

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          ...params,
        },
        callback: `${component.name}.submit`
      });
    };

    const edit = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.createId,
          content: comment.content
        },
        callback: `${component.name}.put`,
      });
    };

    const remove = (commentSeq) => {
      store.commit("confirm", {
        message: "선택하신 항목을 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/maker/project/${props.projectType}/${props.projectSeq}/${props.page}/${commentSeq}/reply`).then(() => {
            store.commit("setSwingMessage", "선택하신 항목을 삭제하였습니다.");
            props.change();
          });
        }
      });
    };

    const submit = (params) => {
      const args = {
        content: params.content,
        groupNum: params.groupNum,
        parentWriterSeq: params.createId,
      };

      if (props.page === "cheer") {
        args.investorSeq = params.groupNum;
      }

      http.post(`/api/maker/project/${props.projectType}/${props.projectSeq}/${props.page}/reply`, args).then(() => {
        store.commit("setSwingMessage", "답변을 등록하였습니다.");
        store.commit("refresh");
      });
    };

    const put = ({commentSeq, content}) => {
      http.put(`/api/maker/project/${props.projectType}/${props.projectSeq}/${props.page}/${commentSeq}/reply`, {content}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        props.change(props.pageIndex, true);
      }).catch(httpError());
    };

    const hide = (investorSeq, restoreFlag, commentBlindSeq) => {
      const args = {
        restoreFlag: restoreFlag,
        commentBlindSeq: commentBlindSeq,
      };

      http.put(`/api/maker/project/${props.page}/${investorSeq}/blind`, args).then(() => {
        store.commit("setSwingMessage", "비공개 처리하였습니다.");
        store.commit("refresh");
      });
    };

    const restore = (commentBlindSeq, restoreFlag) => {
      const args = {
        commentBlindSeq: commentBlindSeq,
        restoreFlag: restoreFlag,
      };

      http.put(`/api/maker/project/${props.page}/${commentBlindSeq}/restore`, args).then(() => {
        store.commit("setSwingMessage", "게시글을 복구하였습니다.");
        store.commit("refresh");
      });
    };

    return {component, state, reply, edit, remove, submit, put, hide, restore};
  }
});
</script>

<style lang="scss" scoped>
.comment {
  color: $colorTextDefault;
  margin-bottom: $px30;

  .wrapper {
    position: relative;
    padding-left: $px47;

    .img {
      width: $px35;
      height: $px35;
      position: absolute;
      top: $px3;
      left: 0;
      border: $px1 solid #eee;
      border-radius: 50%;
    }

    .root {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .profile {
        b {
          margin-right: $px6;
        }
      }

      .actions {
        .btn {
          margin-left: $px4;
          margin-right: 0;
          width: $px40;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .content {
      margin-top: $px5;
      word-break: break-all;
      white-space: pre-line;
    }
  }

  &[data-depth="1"] {
    padding-left: $px47;
    position: relative;

    &:before {
      content: " ";
      background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
      background-size: 100% 100%;
      width: $px20;
      height: $px20;
      position: absolute;
      top: $px5;
      left: $px15;
    }
  }

  &.skeleton {
    .wrapper {
      .img {
        @include skeleton;
      }

      .root {
        .profile {
          b, > span {
            @include skeleton;
          }
        }

        .actions .btn {
          @include skeleton;
        }
      }

      .content > span {
        @include skeleton;
      }
    }
  }
}
</style>