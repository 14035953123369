<template>
  <div class="detail-community-qna" :class="{skeleton:!state.loaded}">
    <div class="comment part" v-if="state.forbidden.code !== '403 FORBIDDEN' || ['MEMBER_MID_ADMIN', 'MEMBER_TOP_ADMIN'].includes($store.state.account.memberAuth) || $store.state.account.memberSeq === openId">
      <div class="form">
        <div class="title font-md bold">궁금한 점이 있다면 작성해주세요.</div>
        <div class="input">
          <textarea class="form-control border-focus-point" :placeholder="$store.state.account.loggedIn ? '내용을 작성하신 후 등록 버튼을 눌러주세요.' : '내용을 입력하시려면 클릭하여 로그인해주세요.'"
                    :readonly="!$store.state.account.loggedIn || !state.loaded" @focus="checkLogin()"
                    ref="commentTextareaRef"></textarea>
          <button class="btn btn-secondary font-sm" @click="post()" :disabled="!$store.state.account.loggedIn || !state.loaded">등록</button>
        </div>
      </div>
      <div class="list" v-if="Array.isArray(state.comments) && state.comments.length" ref="listRef">
        <ul class="tight">
          <li class="font-sm" v-for="(c, idx) in state.comments" :key="idx">
            <Comment
                :commentSeq="c.commentSeq"
                :content="c.content"
                :createDate="c.createDate"
                :createId="c.createId"
                :change="load"
                :delFlag="c.delFlag"
                :depth="c.depth"
                :groupNum="c.groupNum"
                :investSeq="c.investSeq"
                :memberSeq="c.memberSeq"
                :memberName="c.memberName"
                :memberAuth="c.memberAuth"
                :memberType="c.memberType"
                page="qna"
                :projectType="projectType"
                :projectSeq="projectSeq"
                :pageIndex="state.pageIndex"
                :openId="openId"
                :orderNum="c.orderNum"
                :skeleton="!state.loaded"
                :updateDate="c.updateDate"
            />
          </li>
        </ul>
      </div>
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="load" v-if="state.paginationInfo"/>
      </div>
    </div>
    <div v-else class="forbidden">
      <span v-if="$store.state.account.loggedIn">마감된 프로젝트의 최근소식과 Q&A는 투자자와 발행기업의 소통창구로 활용되어, 해당 프로젝트에 배정된 투자자만 확인이 가능합니다.</span>
      <span v-else>
        <span class="login pointer color-anchor" @click="login()">로그인</span>이 필요한 서비스입니다. 로그인 후 이용해주세요.
      </span>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import MemberPicture from "@/components/MemberPicture";
import lib from "@/scripts/lib";
import Comment from "@/components/Comment";

function Component(initialize) {
  this.name = "pageProjectDetailCommunityQna";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, MemberPicture, Pagination},
  props: {
    projectSeq: Number,
    projectType: String,
    openId: String,
  },
  setup(props) {
    const component = new Component(() => {
      for (let i = 0; i < 5; i += 1) {
        state.comments.push({
          content: "Please wait a moment. Please wait a moment.",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          children: []
        });
      }

      load();
    });

    const state = reactive({
      loaded: false,
      paginationInfo: null,
      comments: [],
      forbidden: {
        code: "",
        message: "",
      }
    });

    const commentTextareaRef = ref();
    const listRef = ref();

    const checkLogin = () => {
      !store.state.account.memberSeq && store.dispatch("goLoginPage");
    };

    const post = () => {
      const content = commentTextareaRef.value.value;

      if (!content?.trim()) {
        store.commit("setSwingMessage", "내용을 입력해주세요.");
        commentTextareaRef.value.focus();
        return;
      }

      const args = {
        content,
        createId: store.state.account.memberSeq
      };

      http.post(`/api/${props.projectType}/projects/${props.projectSeq}/qna-comments`, args).then(() => {
        load(1, true);
        commentTextareaRef.value.value = "";
        store.commit("setSwingMessage", "입력하신 질문을 등록하였습니다.");
      });
    };

    const patch = ({commentSeq, content, updateId}) => {
      http.patch(`/api/${props.projectType}/projects/${props.projectSeq}/qna-comments/${commentSeq}`, {content, updateId}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        load(state.pageIndex, true);
      }).catch(httpError());
    };

    const edit = (comment) => {
      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content
        },
        callback: `${component.name}.patch`,
      });
    };

    const login = () => {
      store.dispatch("goLoginPage");
    };

    const remove = (commentSeq) => {
      store.commit("confirm", {
        message: "선택하신 항목을 삭제하시겠습니까?",
        allow() {
          const args = {
            updateId: store.state.account.memberSeq
          };

          http.delete(`/api/${props.projectType}/projects/${props.projectSeq}/qna-comments/${commentSeq}`, args).then(() => {
            store.commit("setSwingMessage", "선택하신 항목을 삭제하였습니다.");
            load();
          });
        }
      });
    };

    const load = (pageIndex, preventScroll) => {
      state.pageIndex = pageIndex ? pageIndex : 1;

      if (listRef.value && !preventScroll) {
        window.scrollTo({
          top: commentTextareaRef.value.getBoundingClientRect().top + window.scrollY - 140 - lib.getHeaderHeight(),
          left: 0,
          behavior: "smooth"
        });
      }

      state.loaded = false;
      state.args = {
        pageIndex: state.pageIndex,
        pageUnit: 30,
      };

      http.get(`/api/${props.projectType}/projects/${props.projectSeq}/qna`, state.args).then(({data}) => {
        state.loaded = true;
        state.comments = [];
        state.comments = data.body.qnaList;
        state.paginationInfo = data.body.paginationInfo;
        state.forbidden.code = data.code;
        state.forbidden.message = data.message;
      });
    };

    return {component, state, commentTextareaRef, listRef, checkLogin, post, patch, edit, remove, login, load};
  },
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.project.detail";

.detail-community-qna {
  .comment {
    .list {
      ul > li {
        padding-left: inherit;
      }
    }
  }

  .forbidden {
    background: #fff;
    border-radius: $px4;
    width: 100%;
    height: $px150;
    display: flex;
    justify-content: center;
    align-items: center;

    .login {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.skeleton {
    .comment .list li {
      .img, .root b, .root > span, .content > span {
        @include skeleton;
      }
    }
  }
}
</style>